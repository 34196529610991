<template>
<div class="p-grid">
        <div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Mis Pólizas')}}</h3>
        </div>
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="poliza" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
                    <template #header>
                        <div class="table-header">
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.estado==4">
                                <Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff"/>
                                {{$t('Verificación  de Pago')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==7">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Proceso Renovación')}}
                            </div>
                            <div v-else-if="slotProps.data.vend<0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Vencida')}}
                            </div>
                            <div v-else-if="slotProps.data.estado==6">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Anulada')}}
                            </div>
                            <div v-else-if="slotProps.data.pendiente>0">
                                <Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff"/>
                                {{$t('Pago Pendiente')}}
                            </div>
                            <div v-else-if="slotProps.data.proceso>0">
                                <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff"/>
                                {{$t('Pago Vencido')}} <br> ({{$t('Suspendida')}})
                            </div>
                            <div v-else>
                                <Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff"/>
                                {{$t('Activa')}}
                            </div>
                        </template>
                    </Column>       
                    <Column :header="$t('Póliza')+' #'" field="codigo" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            <div>
                                {{slotProps.data.codigo}}
                            </div>     
                        </template>
                    </Column>
                    <Column field="duracion_name" :header="$t('Duración')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            <div v-if="i18n.locale() == 'es'">{{slotProps.data.duracion_nombre}}</div>
                            <div v-else>{{slotProps.data.duracion_name}}</div>     
                        </template>
                    </Column>
                    <Column field="activacion" :header="$t('Activación')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.activacion}}
                        </template>
                    </Column>
                    <Column field="vence" :header="$t('Vencimiento')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.vence}}
                        </template>
                    </Column>
                    <Column field="cuotas" :header="$t('Cuotas')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.cuotas}}
                        </template>
                    </Column>
                    <Column :header="$t('Certificado')" :sortable="false" headerStyle="width: 100px">
                        <template #body="slotProps">
                            <Button :disabled ="$store.state.loading" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="VerPDF(slotProps.data.firmado)" v-if="slotProps.data.firmado && slotProps.data.estado==5 && slotProps.data.vend>=0 && slotProps.data.pendiente<=0 && slotProps.data.proceso==0"/>
                            <Button :disabled ="$store.state.loading" label="Renovar" icon="pi pi-refresh" class="p-button-rounded p-button-danger p-mr-2" @click="Renovacion(slotProps.data)" v-if="slotProps.data.vend<=3 && slotProps.data.deudas==0 && slotProps.data.estado==5 && slotProps.data.vend>=(-30)"/>
                        </template>
                    </Column>
                    <Column :header="$t('Términos y Condiciones')" :sortable="false" headerStyle="width: 100px">
                        <template #body="slotProps">
                            <Button :disabled ="$store.state.loading" icon="pi pi-file-pdf" class="p-button-rounded p-button-secondary p-mr-2" @click="VerTer(slotProps.data.tipo)" v-if="slotProps.data.firmado"/>
                        </template>
                    </Column>
                </DataTable>
			</div>
        </div>
	</div>
</template>

<script>
import API from "../service/API";
const Consulta = new API('Polizas','MisPolizas');
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";

export default {
	components: {
    },
	data() {
		return {
            selectedCustomers: null,
			filters: {},
			i18n: null,
            caso: null,
            poliza: [],
			url: null,
		}
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
        Mostrar(){
            Consulta.Ini().then(result => {
                if (result.consult.poliza){
                    this.poliza = result.consult.poliza;
                }
            }); 
        },
        Solicitar(product){
			Consulta.Procesar('Solicitar',{
				id: product.id,
                duracion: product.duracion,
                cuotas: product.cuotas,
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
                });
        },
        VerCompromiso(product){
            if (product){
                Consulta.Procesar('Compromiso',{
                    poliza: product,
                    duracion: this.duracion[product.duracion].valor,
                    cuotas: this.cuotas[product.cuotas].valor,
                }).then(response => {
                    if (response.result) {
                        this.$toast.add({
                            severity:'success', 
                            summary: 'Éxito', 
                            detail: 'Información Procesada', 
                            life: 3000
                        });
                        this.$router.push({ path: '/payment'});
                    } else {
                        this.$toast.add({
                            severity:'warn', 
                            summary: 'Advertencia', 
                            detail: 'No fue procesada la solicitud', 
                            life: 3000
                        });
                    }
                });
            }
        },
        VerPDF(data){
            window.open(url.api+'firmado/'+data, '_blank');
        },
        VerTer(tipo){
            if(tipo==1){window.open('terms/fire.pdf', '_blank');}
            if(tipo==2){window.open('terms/vehicle.pdf', '_blank');}
            if(tipo==3){window.open('terms/health.pdf', '_blank');}
            if(tipo==4){window.open('terms/business.pdf', '_blank');}
        },
        Renovacion(data) {
            this.$store.commit('Mobile');
			Consulta.Procesar('RENOVAR',{
                poliza: data,
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
                        this.displayrenov = false;
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
                        this.displayrenov = false;
					}
                });           
        },
        Ver(data){
            const doc = new PDF();
            doc.Certificado(data);
        },
        close() {
            this.caso = null;
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		toggle3(event) {
			this.$refs.op3.toggle(event);
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},


	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
